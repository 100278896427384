import { Routes } from "@blitzjs/next"
import { NextRouter } from "next/router"

import { NavItem, SitePath } from "../types"

export const homeNavItem = { name: "INSTRUCT", id: "go-home", route: Routes.Home() }
const coinNavItem: NavItem = {
  name: "{INS}coin",
  id: "go-coin",
  route: Routes.CoinPage(),
}
const articlesNavItem: NavItem = {
  name: "Articles",
  id: "go-articles",
  route: Routes.ArticlesPage(),
}
const solutionsNavItem: NavItem = {
  name: "Solutions",
  id: "go-solutions",
  route: Routes.SolutionsPage(),
}
const questionsNavItem: NavItem = {
  name: "Questions",
  id: "go-questions",
  route: Routes.QuestionsPage(),
}

const defaultSiteNavItems: NavItem[] = [solutionsNavItem, questionsNavItem]

export const isHomeNavItemActive = (router: NextRouter) =>
  homeNavItem.route.pathname === router.pathname

export const getSiteNavItems = (router: NextRouter, { hasArticles, hasCoin }): NavItem[] => {
  let siteNavItems = defaultSiteNavItems

  if (hasArticles) {
    siteNavItems = [...siteNavItems, articlesNavItem]
  }
  if (hasCoin) {
    siteNavItems = [coinNavItem, ...siteNavItems]
  }

  return siteNavItems.map(({ route, ...item }) => ({
    ...item,
    route,
    isRouteActive: router.pathname.includes(route.pathname),
  }))
}

export const getParentNavItemByPathname = (pathname: SitePath | string): NavItem => {
  if (pathname.includes(SitePath.Questions)) {
    return questionsNavItem
  } else if (pathname.includes(SitePath.Solutions)) {
    return solutionsNavItem
  } else if (pathname.includes(SitePath.Articles)) {
    return articlesNavItem
  } else return homeNavItem
}
