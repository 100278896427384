import { AppProps, ErrorBoundary, ErrorComponent, ErrorFallbackProps } from "@blitzjs/next"
import { useQueryErrorResetBoundary } from "@blitzjs/rpc"
import { ThemeProvider } from "@emotion/react"
import flagsmith from "flagsmith/isomorphic"
import { FlagsmithProvider } from "flagsmith/react"
import Head from "next/head"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { withBlitz } from "src/blitz-client"
import { NotifyProvider } from "src/core/providers/NotifyProvider"
import { LoginPageContent } from "src/pages/auth/login"
import theme from "src/theme"

import { AuthenticationError, AuthorizationError } from "blitz"

import { trackPageview } from "integrations/analytics"
import { GoogleAnalyticsScripts } from "integrations/components/GoogleAnalyticsScripts"
import { trackError } from "integrations/errors"
import { initHotjar } from "integrations/helpers/hotjarHelpers"

import { IState as FlagsmithState } from "flagsmith/types"

const SERVER_ERROR_MESSAGE = "Server Error, we are on it. Sorry, please try again later."
const flagsmithClientEnvID = process.env.NEXT_PUBLIC_FLAGSMITH_CLIENT_ENV_ID as string
const flagsmithServerEnvID = process.env.FLAGSMITH_SERVER_ENV_ID as string

function RootErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  const errorTitle = error.statusCode === 500 ? SERVER_ERROR_MESSAGE : error.message || error.name

  if (error instanceof AuthenticationError) {
    return <LoginPageContent onSuccess={resetErrorBoundary} />
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    )
  } else {
    trackError(error, { resetErrorBoundary })
    return <ErrorComponent statusCode={error.statusCode} title={errorTitle} />
  }
}

interface InstructAppProps extends AppProps {
  flagsmithState: FlagsmithState
}

function App({ Component, pageProps, flagsmithState }: InstructAppProps, ctx) {
  const getLayout = Component.getLayout || ((page) => page)
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url) => {
      trackPageview(url)
    }
    router.events.on("routeChangeComplete", handleRouteChange)
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    initHotjar()
  }, [])

  return (
    <FlagsmithProvider
      serverState={flagsmithState}
      options={{ environmentID: flagsmithClientEnvID }}
      flagsmith={flagsmith}
    >
      <ThemeProvider theme={theme}>
        <ErrorBoundary
          FallbackComponent={RootErrorFallback}
          onReset={useQueryErrorResetBoundary().reset}
        >
          <Head>
            <meta name="viewport" content="initial-scale=1, width=device-width" />
            <GoogleAnalyticsScripts />
          </Head>
          <NotifyProvider>{getLayout(<Component {...pageProps} />)}</NotifyProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </FlagsmithProvider>
  )
}

App.getStaticProps = async () => {
  await flagsmith.init({ environmentID: flagsmithServerEnvID })
  return {
    flagsmithState: flagsmith.getState(),
  }
}

export default withBlitz(App)
