import { Routes } from "@blitzjs/next"
import { useRouter } from "next/router"
import * as React from "react"

import EditIcon from "@mui/icons-material/Edit"
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined"
import PrintIcon from "@mui/icons-material/Print"
import QuestionMarkIcon from "@mui/icons-material/QuestionMark"
import ShareIcon from "@mui/icons-material/Share"
import { Box, SpeedDial, SpeedDialIcon, styled } from "@mui/material"
import SpeedDialAction from "@mui/material/SpeedDialAction"

import { getFabMenuHideTimeout } from "src/core/helpers/delayHelpers"

const actions = [
  { icon: <QuestionMarkIcon />, name: "New Question", route: Routes.NewQuestionPage() },
  { icon: <EditIcon />, name: "New Solution", route: Routes.NewSolutionPage() },
]

// TODO(pt/182315513): Add solution context menu
const solutionActions = [
  { icon: <FileCopyIcon />, name: "Edit the Copy" },
  { icon: <PrintIcon />, name: "Print" },
  { icon: <ShareIcon />, name: "Share" },
]

const getDialButtonStyle = ({ theme }) => `
  background-color: ${theme.palette.primary.main};
  color: ${theme.palette.primary.contrastText};
`

const StyledSpeedDial = styled(SpeedDial)`
  #SpeedDialMenu-actions > span {
    & > span {
      white-space: nowrap;
    }

    & > button {
      ${getDialButtonStyle}
    }
  }
`

const FloatingMenuButton: React.FunctionComponent = () => {
  const [clickOpen, setClickOpen] = React.useState(false)
  const [hoverOpen, setHoverOpen] = React.useState(false)

  const router = useRouter()

  let timer: NodeJS.Timeout

  const open = () => {
    setHoverOpen(true)
    setClickOpen(true)
  }

  const close = () => {
    setClickOpen(false)
    setHoverOpen(false)
  }

  const debounceHoverClose = () => {
    clearTimeout(timer)
    timer = setTimeout(() => setHoverOpen(false), getFabMenuHideTimeout())
  }

  const handleHoverOpen = () => {
    clearTimeout(timer)
    setHoverOpen(true)
  }

  const handleClickToggle = () => {
    clearTimeout(timer)
    const isOpened = clickOpen
    isOpened ? close() : open()
  }

  const handleClose = (event?) => {
    if (!hoverOpen) return

    event.preventDefault()
    handleHoverClose()
  }

  const handleOpen = () => {
    if (clickOpen) return

    setHoverOpen(true)
  }

  const handleHoverClose = () => {
    debounceHoverClose()
  }

  const handleSpeedDialAction = (action) => {
    if (action.route) {
      router.push(action.route.pathname)
    }

    close()
  }

  const fabShadowStyle = clickOpen ? { boxShadow: "unset" } : { boxShadow: "" }

  return (
    <Box data-control-id="floating-menu-button">
      <StyledSpeedDial
        ariaLabel="SpeedDial Menu"
        sx={{ position: "fixed", bottom: 55, right: 35 }}
        icon={<SpeedDialIcon />}
        open={clickOpen || hoverOpen}
        FabProps={{ onClick: handleClickToggle, sx: fabShadowStyle }}
        onOpen={handleOpen}
        onMouseEnter={handleHoverOpen}
        onClose={handleClose}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            FabProps={{ sx: fabShadowStyle }}
            onMouseEnter={handleHoverOpen}
            onMouseLeave={handleHoverClose}
            onClick={() => handleSpeedDialAction(action)}
          />
        ))}
      </StyledSpeedDial>
    </Box>
  )
}

export default FloatingMenuButton
