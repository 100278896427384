import { ParsedUrlQueryInput } from "querystring"

import { RouteUrlObject } from "blitz"

export type NavItem = {
  id: string
  name: string
  iconName?: string
  route: RouteUrlObject
  routeFilterQuery?: ParsedUrlQueryInput
  isRouteActive?: boolean
}

export enum SitePath {
  Articles = "/articles",
  Solutions = "/solutions",
  Questions = "/questions",
}
