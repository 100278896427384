import { useCallback, useEffect } from "react"
import { useNotify } from "src/core/providers/NotifyProvider"

import { Container } from "@mui/material"

import { getHideNotifyDelay } from "../helpers/delayHelpers"

import { NotifyAlert } from "./NotifyAlert"

export const AlertNotifier = () => {
  const { notifyMessage, setNotifyMessage } = useNotify()

  const closeNotify = useCallback(() => {
    setNotifyMessage(null)
  }, [setNotifyMessage])

  const handleCloseNotify = closeNotify

  useEffect(() => {
    setTimeout(closeNotify, getHideNotifyDelay())
  }, [notifyMessage, closeNotify])

  return notifyMessage ? (
    <Container maxWidth="sm">
      <NotifyAlert message={notifyMessage} onClose={handleCloseNotify} />
    </Container>
  ) : (
    <></>
  )
}
