import { BlitzLayout } from "@blitzjs/next"
import Head from "next/head"
import React, { ReactElement } from "react"

import { Container, CssBaseline } from "@mui/material"

import { Footer, FooterProps } from "./components/Footer"
import { Header } from "./components/Header"

interface LayoutProps extends FooterProps {
  title?: string
  children: React.ReactElement
  hasHeader?: boolean
  hasFooter?: boolean
  sidebar?: ReactElement
}

const Layout: BlitzLayout<LayoutProps> = ({
  title,
  children,
  hasHeader = true,
  hasFooter = true,
  sidebar,
  ...footerProps
}) => {
  const containerStyle = !!sidebar ? { display: "flex" } : { mt: 10, mb: 20 }

  return (
    <>
      <CssBaseline />
      <Head>
        <title>{title || "INSTRUCT"}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {hasHeader && <Header />}
      <Container maxWidth="lg" sx={containerStyle}>
        {sidebar}
        {children}
      </Container>
      {hasFooter && <Footer {...footerProps} />}
    </>
  )
}

export default Layout
