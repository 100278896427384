enum NotifySubject {
  SOLUTION = "Solution",
  QUESTION = "Question",
  STEP = "Step",
  CHECKLIST = "Checklist",
}

enum NotifyAction {
  CREATED = "created",
  UPDATED = "updated",
  DELETED = "deleted",
  CLONED = "cloned",
}

export const getUpdateNotifyMessage = (subject, action) => {
  return `${subject} successfully ${action}`
}

const getAction = (isCreating: boolean) =>
  isCreating ? NotifyAction.CREATED : NotifyAction.UPDATED

export const getSolutionUpdatedNotifyMessage = (isCreating) => {
  return getUpdateNotifyMessage(NotifySubject.SOLUTION, getAction(isCreating))
}

export const getSolutionDeletedNotifyMessage = () => {
  return getUpdateNotifyMessage(NotifySubject.SOLUTION, NotifyAction.DELETED)
}

export const getSolutionClonedNotifyMessage = () => {
  return getUpdateNotifyMessage(NotifySubject.SOLUTION, NotifyAction.CLONED)
}

export const getQuestionUpdatedNotifyMessage = (isCreating) => {
  return getUpdateNotifyMessage(NotifySubject.QUESTION, getAction(isCreating))
}

export const getQuestionDeletedNotifyMessage = () => {
  return getUpdateNotifyMessage(NotifySubject.QUESTION, NotifyAction.DELETED)
}

export const getAuthLogoutMessage = () => {
  return "Logged out successfully"
}

export const getStepCreatedNotifyMessage = () => {
  return getUpdateNotifyMessage(NotifySubject.STEP, NotifyAction.CREATED)
}

export const getStepUpdatedNotifyMessage = () => {
  return getUpdateNotifyMessage(NotifySubject.STEP, NotifyAction.UPDATED)
}

export const getStepDeletedNotifyMessage = () => {
  return getUpdateNotifyMessage(NotifySubject.STEP, NotifyAction.DELETED)
}

export const getChecklistCreatedNotifyMessage = () => {
  return getUpdateNotifyMessage(NotifySubject.CHECKLIST, NotifyAction.CREATED)
}

export const getChecklistsDeletedNotifyMessage = () => {
  return getUpdateNotifyMessage(NotifySubject.CHECKLIST, NotifyAction.DELETED)
}
