import * as Sentry from "@sentry/nextjs"

import { isProduction } from "src/core/helpers/versionUiHelpers"

/** Track error to sentry.io **/
export const trackError = async (error: Error, ...errorContext: any) => {
  if (!isProduction) {
    console.error(error, { cause: error.cause }, ...errorContext)
  }
  Sentry.captureException(error, errorContext)
  await Sentry.flush(2000)
}
