import * as React from "react"

interface NotifyContextValue {
  notifyMessage: string
  setNotifyMessage: React.Dispatch<React.SetStateAction<string | null>>
}

const NotifyContext = React.createContext<NotifyContextValue | undefined>(undefined)

export const NotifyProvider: React.FunctionComponent<{
  initMessage?: string
  children?: React.ReactNode
}> = ({ initMessage = "", children }) => {
  const [notifyMessage, setNotifyMessage] = React.useState<string>(initMessage)

  const providerValue = React.useMemo<NotifyContextValue>(
    () => ({ notifyMessage, setNotifyMessage }),
    [notifyMessage, setNotifyMessage]
  )

  return <NotifyContext.Provider value={providerValue}>{children}</NotifyContext.Provider>
}

export const useNotify = (): NotifyContextValue => {
  const result = React.useContext(NotifyContext)
  if (result === undefined) {
    throw new Error("No NotifyContext value available")
  }

  return result
}
