import { useFlags } from "flagsmith/react"

import { Box, Container, Typography, styled } from "@mui/material"

import { getOrigin, getYear } from "src/core/helpers/appHelpers"
import { getTransparentStyledBg } from "src/core/helpers/styleUiHelpers"
import { getAppVersion } from "src/core/helpers/versionUiHelpers"

import Link from "src/core/components/Link"

import FloatingMenuButton from "./FloatingMenuButton"
import { FooterBreadcrumbs } from "./FooterBreadcrumbs"

const Copyright = () => {
  const flags = useFlags(["footer_version"])
  const hasVersion = getAppVersion() && flags.footer_version.enabled

  return (
    <Typography variant="body2" align="center">
      {"Copyright © "}
      <Link href={getOrigin()}>INSTRUCT</Link> {getYear()}
      {"."} {hasVersion && <>Version: {getAppVersion()}</>}
    </Typography>
  )
}

const StyledContainer = styled(Container)`
  ${getTransparentStyledBg()}
`

export type FooterProps = {
  hasBreadcrumbs?: boolean
  hasFloatingMenuButton?: boolean
}

const fixedBottomStyle = {
  position: "fixed",
  bottom: 0,
  width: "100%",
}

export const Footer: React.FC<FooterProps> = ({
  hasBreadcrumbs = false,
  hasFloatingMenuButton = false,
}) => {
  const flags = useFlags(["fixed_footer", "floating_menu_button"])
  const containerStyle = flags.fixed_footer.enabled ? fixedBottomStyle : {}

  return (
    <>
      <Box sx={containerStyle}>
        <StyledContainer data-control-id="footer-container" maxWidth="lg">
          {hasBreadcrumbs && <FooterBreadcrumbs />}

          <Box
            component="footer"
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Box sx={{ p: "5px 10px", mb: "10px" }}>
              <Copyright />
              <Typography variant="subtitle2" align="center" component="p">
                <Link href="https://blitzjs.com?utm_source=blitz-new&utm_medium=app-template&utm_campaign=blitz-new">
                  Powered by Blitz.js
                </Link>
              </Typography>
            </Box>
          </Box>
        </StyledContainer>
      </Box>

      {flags.floating_menu_button.enabled && hasFloatingMenuButton && <FloatingMenuButton />}
    </>
  )
}
