import { BlitzPage, Routes } from "@blitzjs/next"
import { useRouter } from "next/router"
import React from "react"
import Layout from "src/core/layouts/Layout"

import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Box, Fab, Grid, Typography } from "@mui/material"
import Container from "@mui/material/Container"

import { LoginForm } from "src/auth/components/LoginForm"
import Link from "src/core/components/Link"

type LoginPageContentProps = {
  onSuccess?: () => void
}

export const LoginPageContent: React.FC<LoginPageContentProps> = ({ onSuccess }) => {
  const router = useRouter()

  const handleBack = () => {
    router.back()
  }
  const isBackButton = !!onSuccess

  return (
    <Container maxWidth="lg">
      {isBackButton && (
        <Grid container marginTop={4} marginLeft={4} justifyContent="start">
          <Fab variant="extended" color="primary" aria-label="back" onClick={handleBack}>
            <ArrowBackIcon sx={{ mr: 1 }} />
            Back
          </Fab>
        </Grid>
      )}
      <Box
        component="main"
        sx={{
          marginTop: isBackButton ? 5 : 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h3">
          Sign In
        </Typography>

        <Box component="div" sx={{ mt: 10, minWidth: 300 }}>
          <LoginForm
            onSuccess={
              onSuccess ||
              ((_user) => {
                const next = router.query.next
                  ? decodeURIComponent(router.query.next as string)
                  : "/"
                router.push(next)
              })
            }
          />

          <Typography variant="body2" align="center" sx={{ mt: 2 }}>
            Don&apos;t have an account?&nbsp;
            <Link data-control-id="go-signup" href={Routes.SignupPage()}>
              Register
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

const LoginPage: BlitzPage = () => {
  return <LoginPageContent />
}

LoginPage.redirectAuthenticatedTo = "/"
LoginPage.getLayout = (page) => <Layout title="Log In">{page}</Layout>

export default LoginPage
