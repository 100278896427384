import { Routes } from "@blitzjs/next"
import { useMutation } from "@blitzjs/rpc"
import * as React from "react"
import { Login } from "src/auth/validations"
import { z } from "zod"

import { AuthenticationError, PromiseReturnType } from "blitz"

import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

import { trackError } from "integrations/errors"

import login from "src/auth/mutations/login"

import { FORM_ERROR, Form } from "src/core/components/Form"
import { LabeledTextField } from "src/core/components/LabeledTextField"
import Link from "src/core/components/Link"

type LoginFormProps = {
  onSuccess?: (user: PromiseReturnType<typeof login>) => void
}

const initFormValues = {
  email: "",
  password: "",
}

export const LoginForm: React.FunctionComponent<LoginFormProps> = ({ onSuccess }) => {
  const [loginMutation] = useMutation(login)

  const handleSubmit = async (loginFormValues) => {
    try {
      const user = await loginMutation(loginFormValues)
      onSuccess?.(user)
    } catch (error: any) {
      if (error instanceof AuthenticationError) {
        return { [FORM_ERROR]: "Sorry, those credentials are invalid" }
      } else if (error instanceof z.ZodError) {
        return { [FORM_ERROR]: error.toString() }
      } else {
        await trackError(error, { loginFormValues, onSuccess })
        return {
          [FORM_ERROR]:
            "Sorry, we had an unexpected error. Please try again. - " + error.toString(),
        }
      }
    }
  }

  return (
    <Form
      data-control-id="login-form"
      submitText="Sign in"
      schema={Login}
      initialValues={initFormValues}
      onSubmit={handleSubmit}
    >
      <LabeledTextField
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
      />

      <LabeledTextField
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        autoComplete="new-password"
      >
        <Typography align="right">
          <Link
            data-control-id="go-forgot-password"
            muiLinkProps={{ variant: "body2" }}
            href={Routes.ForgotPasswordPage()}
          >
            Forgot password?
          </Link>
        </Typography>
      </LabeledTextField>
      <Box textAlign="end" sx={{ mb: 3 }}>
        {/* TODO(pt/181915769) Implement remember me
          <FormControlLabel
            control={<Checkbox value="rememberMe" color="primary" />}
            label="Remember me"
          />
        */}
      </Box>
    </Form>
  )
}

export default LoginForm
