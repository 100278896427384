import { Routes } from "@blitzjs/next"
import { useMutation } from "@blitzjs/rpc"
import React, { Suspense } from "react"
import { useCurrentUser } from "src/core/hooks/useCurrentUser"

import { AccountCircle } from "@mui/icons-material"
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material"

import logout from "src/auth/mutations/logout"

import { getAuthLogoutMessage } from "../helpers/msgUiHelpers"

import { useNotify } from "../providers/NotifyProvider"
import Link from "./Link"

const UserInfoContent = () => {
  const currentUser = useCurrentUser()
  const [logoutMutation] = useMutation(logout)
  const [anchorUserEl, setAnchorUserEl] = React.useState<null | HTMLElement>(null)
  const [anchorAuthEl, setAnchorAuthEl] = React.useState<null | HTMLElement>(null)
  const { setNotifyMessage } = useNotify()

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUserEl(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorUserEl(null)
  }

  const handleOpenAuthMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorAuthEl(event.currentTarget)
  }

  const handleCloseAuthMenu = () => {
    setAnchorAuthEl(null)
  }

  const handleLogout = async () => {
    await logoutMutation()

    setNotifyMessage(getAuthLogoutMessage())
  }

  if (currentUser) {
    return (
      <>
        <Button
          data-control-id="toggle-user-menu"
          color="secondary"
          aria-label="Current user menu"
          aria-controls="menu-appbar-user"
          aria-haspopup="true"
          onClick={handleOpenUserMenu}
          sx={{ textTransform: "none" }}
        >
          <Avatar alt={currentUser.name || ""} sx={{ width: 30, height: 30 }} />
          <Typography component="span" sx={{ display: { xs: "none", md: "flex" }, mr: 1, ml: 1 }}>
            {currentUser.name || currentUser.email}
          </Typography>
        </Button>
        <Menu
          sx={{ mt: "5px" }}
          anchorEl={anchorUserEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorUserEl)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem data-control-id="start-logout" onClick={handleLogout}>
            Logout
          </MenuItem>
        </Menu>
      </>
    )
  } else {
    return (
      <>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton
            data-control-id="toggle-auth-menu"
            size="large"
            aria-label="Authentication menu"
            aria-controls="menu-appbar-auth"
            aria-haspopup="true"
            onClick={handleOpenAuthMenu}
            color="primary"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={anchorAuthEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorAuthEl)}
            onClose={handleCloseAuthMenu}
          >
            <Link data-control-id="go-login" href={Routes.LoginPage()}>
              <MenuItem>Login</MenuItem>
            </Link>
            <Link data-control-id="go-signup" href={Routes.SignupPage()}>
              <MenuItem>Register</MenuItem>
            </Link>
          </Menu>
        </Box>
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <Link data-control-id="go-login" href={Routes.LoginPage()}>
            <Button>Login</Button>
          </Link>
          <Link data-control-id="go-signup" href={Routes.SignupPage()}>
            <Button variant="contained">Register</Button>
          </Link>
        </Box>
      </>
    )
  }
}

const UserInfo = () => (
  <Suspense fallback={<CircularProgress />}>
    <UserInfoContent />
  </Suspense>
)

export default UserInfo
