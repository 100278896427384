import NextLink, { LinkProps as NextLinkProps } from "next/link"
import React, { ReactElement } from "react"

import { Link as MuiLink, LinkProps as MuiLinkProps, styled } from "@mui/material"

const StyledMuiLink = styled(MuiLink)`
  @media screen and (max-width: 40em) {
    button {
      width: 100%;
    }
  }
`
export interface LinkProps extends NextLinkProps {
  muiLinkProps?: MuiLinkProps
  children: ReactElement | string
}

const Link: React.FC<LinkProps> = ({ children, href, muiLinkProps }) => {
  const sxProps =
    children instanceof Object
      ? { color: "inherit", textDecoration: "none" }
      : { cursor: "pointer" }

  const createLink = (href?) => (
    <StyledMuiLink
      data-control-id={href ? "url-link" : "route-link"}
      href={href}
      {...muiLinkProps}
      sx={{ ...sxProps, ...muiLinkProps?.sx }}
    >
      {children}
    </StyledMuiLink>
  )

  return typeof href === "string" ? (
    createLink(href)
  ) : (
    <NextLink href={href}>{createLink()}</NextLink>
  )
}

export default Link
