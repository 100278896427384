import * as React from "react"

import CommitIcon from "@mui/icons-material/Commit"
import MergeIcon from "@mui/icons-material/Merge"
import { Box, IconButton, Typography } from "@mui/material"

import { getGithubDeployInfo } from "../helpers/githubHelpers"

import Link from "./Link"

export const DeployInfoPane = () => {
  const { pullRequestUrl, pullRequestLinkCaption, commitUrl, commitLinkCaption } =
    getGithubDeployInfo()
  const icon = pullRequestUrl ? <MergeIcon color="success" /> : <CommitIcon color="warning" />

  return (
    <Box data-control-id="deploy-info-pane" sx={{ display: "flex", gap: 1, m: 1 }}>
      <Box>
        <IconButton
          size="small"
          href={pullRequestUrl || commitUrl}
          sx={{ backgroundColor: "background.paper", transform: "rotate(90deg)" }}
        >
          {icon}
        </IconButton>
      </Box>
      <Box>
        {pullRequestUrl && (
          <Typography fontWeight="bold" fontSize="sm">
            <Link
              muiLinkProps={{
                sx: { color: "background.paper", textDecorationLine: "none" },
                target: "_blank",
              }}
              href={pullRequestUrl}
            >
              {pullRequestLinkCaption}
            </Link>
          </Typography>
        )}
        {commitUrl && (
          <Typography fontSize="sm">
            <Link
              muiLinkProps={{
                sx: { color: "background.paper", textDecorationLine: "none" },
                target: "_blank",
              }}
              href={commitUrl}
            >
              {commitLinkCaption}
            </Link>
          </Typography>
        )}
      </Box>
    </Box>
  )
}
