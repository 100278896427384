import { Theme, createTheme } from "@mui/material"

enum BrandColor {
  ORANGE = "#FF9400",
  LIGHT_ORANGE = "rgba(255, 148, 0, 0.08)",
  LIGHT_GRAY = "#929292",
  DARK_TEXT = "#1B1E2F",
  LIGHT_TEXT = "#FFF",
  BACKGROUND_DEFAULT = "rgba(255, 255, 255, 0.7)",
  BACKGROUND_PAPER = "rgba(250, 250, 250, 0.9)",
}

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: BrandColor.ORANGE,
      light: BrandColor.LIGHT_ORANGE,
      contrastText: BrandColor.LIGHT_TEXT,
    },
    secondary: {
      main: BrandColor.DARK_TEXT,
    },
    background: {
      default: BrandColor.BACKGROUND_DEFAULT,
      paper: BrandColor.BACKGROUND_PAPER,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    subtitle1: {
      fontSize: 24,
      lineHeight: 2,
    },
    subtitle2: {
      color: BrandColor.LIGHT_GRAY,
    },
  },
})

export default theme
