import { Box, Typography } from "@mui/material"

import { homeNavItem } from "src/core/helpers/navigationHelpers"

import { DeployEnvButton } from "src/core/components/DeployEnvButton"
import Link from "src/core/components/Link"

const TITLE_WIDTH = 110

type HeaderTitleProps = {
  isHomePage: boolean
  position: "left" | "center"
}

export const HeaderTitle: React.FC<HeaderTitleProps> = ({ isHomePage, position }) => {
  const isCenter = position === "center"
  const containerStyle = isCenter
    ? { flexGrow: 1, display: { xs: "flex", md: "none" } }
    : { display: { xs: "none", md: "flex" } }

  const homePageLink = (
    <Link data-control-id={homeNavItem.id} href={homeNavItem.route}>
      <Typography color="secondary.main" sx={{ cursor: "pointer", fontSize: 20 }}>
        {homeNavItem.name}
      </Typography>
    </Link>
  )
  return (
    <Box
      data-container-id="header-title"
      sx={{
        minWidth: TITLE_WIDTH,
        ...containerStyle,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {!isHomePage && homePageLink}
        <DeployEnvButton />
      </Box>
    </Box>
  )
}
