import { useTheme } from "@emotion/react"
import { useFlags } from "flagsmith/react"
import { useRouter } from "next/router"
import React from "react"

import MenuIcon from "@mui/icons-material/Menu"
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
  styled,
} from "@mui/material"

import { getSiteNavItems, isHomeNavItemActive } from "src/core/helpers/navigationHelpers"
import {
  getHeaderMenuItemBorderStyle,
  getTransparentStyledBg,
} from "src/core/helpers/styleUiHelpers"

import { AlertNotifier } from "src/core/components/AlertNotifier"
import Link from "src/core/components/Link"
import UserInfo from "src/core/components/UserInfo"

import { HeaderTitle } from "./HeaderTitle"

const StyledAppBar = styled(AppBar)`
  border: none;
  ${getTransparentStyledBg()}
`

const NavItemName = ({ children: name }) => {
  const re = /(.*)\{(.*)\}(.*)/

  return re.test(name) ? (
    <>
      {name.match(re)[1]}
      <Typography color="primary.main" variant="inherit" component="span">
        {name.match(re)[2]}
      </Typography>
      {name.match(re)[3]}
    </>
  ) : (
    name
  )
}

export const Header: React.FC = () => {
  const MOBILE_STYLE = true
  const theme = useTheme() as Theme
  const router = useRouter()
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

  const isHomePage = isHomeNavItemActive(router)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const flags = useFlags(["articles_nav_item", "coin_nav_item"]) // only causes re-render if specified flag values / traits change

  // TODO(pt/183998536): Implement articles fetch
  const navItems = getSiteNavItems(router, {
    hasArticles: flags.articles_nav_item.enabled,
    hasCoin: flags.coin_nav_item.enabled,
  })

  return (
    <StyledAppBar
      data-control-id="header"
      position="fixed"
      color="primary"
      variant="outlined"
      elevation={0}
      sx={{ backgroundColor: "background.default" }}
    >
      <Container maxWidth="lg">
        <Toolbar sx={{ flexGrow: 1 }} disableGutters>
          <HeaderTitle position="left" isHomePage={isHomePage} />

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              data-control-id="toggle-nav-menu"
              size="large"
              aria-label="Site navigation menu"
              aria-controls="menu-appbar-site"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon color="primary" />
            </IconButton>
            <Menu
              color="secondary.main"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {navItems.map((navItem) => (
                <Link key={navItem.id} data-control-id={navItem.id} href={navItem.route}>
                  <MenuItem
                    selected={navItem.isRouteActive}
                    sx={getHeaderMenuItemBorderStyle(MOBILE_STYLE, !!navItem.isRouteActive, theme)}
                  >
                    <NavItemName>{navItem.name}</NavItemName>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>

          <HeaderTitle position="center" isHomePage={isHomePage} />

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, align: "start" }}>
            {navItems.map((navItem) => (
              <Link key={navItem.id} data-control-id={navItem.id} href={navItem.route}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    ...getHeaderMenuItemBorderStyle(!MOBILE_STYLE, !!navItem.isRouteActive, theme),
                    my: 2,
                    color: "secondary.main",
                    display: "block",
                  }}
                >
                  <NavItemName>{navItem.name}</NavItemName>
                </Button>
              </Link>
            ))}
          </Box>
          <UserInfo />
        </Toolbar>
      </Container>
      <AlertNotifier />
    </StyledAppBar>
  )
}
