import appTheme from "src/theme"

const BORDER_WIDTH_PX = "2px"

const transparentStyledBg = `
  backdrop-filter: blur(10px);
  @supports not (backdrop-filter: blur(10px)) {
    background-color: ${appTheme.palette.background.paper};
  }
`

export const getTransparentStyledBg = () => transparentStyledBg

export const getHeaderMenuItemBorderStyle = (
  isMobile: boolean,
  isRouteActive: boolean,
  theme = appTheme
) => {
  const borderBottomStyle = {
    borderBottom: `solid ${BORDER_WIDTH_PX} ${appTheme.palette.primary.main}`,
  }

  const marginBottomStyle = {
    marginBottom: BORDER_WIDTH_PX,
  }

  if (isMobile) {
    return isRouteActive ? borderBottomStyle : marginBottomStyle
  }

  const radiusStyle = { borderRadius: 0 }
  const borderStyle = isRouteActive
    ? {
        background: theme.palette.primary.light,
        ...borderBottomStyle,
      }
    : marginBottomStyle

  return { ...borderStyle, ...radiusStyle }
}

export const getNavigationListItemStyle = (isRouteActive: boolean, theme = appTheme) => {
  return isRouteActive
    ? {
        borderLeft: `solid ${BORDER_WIDTH_PX} ${theme.palette.primary.main}`,
      }
    : {
        marginLeft: BORDER_WIDTH_PX,
      }
}
