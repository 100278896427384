import getConfig from "next/config"

const config = getConfig()?.publicRuntimeConfig

type TagColor = "error" | "info" | "success"

enum DeployEnv {
  DEV = "development",
  STAGE = "preview",
  PROD = "production",
}

type DevModeInfo = {
  label: string
  color: TagColor
}

export const getDevModeInfo = (): DevModeInfo => {
  const tagInfo = {
    label: getDevModeLabel(),
    color: getDevModeColor(),
  }
  return tagInfo
}

const getDevModeColor = (): TagColor => {
  switch (getDeployEnv()) {
    case DeployEnv.PROD:
      return "success"
    case DeployEnv.DEV:
      return "info"
    case DeployEnv.STAGE:
    default:
      return "error"
  }
}

const getDevModeLabel = (): string => {
  const env = getDeployEnv()

  switch (env) {
    case DeployEnv.DEV:
      return "DEV"
    case DeployEnv.PROD:
      return "Alpha"
    default:
      return env
  }
}

export const getAppVersion = (): string => {
  return config?.packageVersion || ""
}

export const getNodeEnv = (): string => {
  return process.env.NEXT_PUBLIC_NODE_ENV || "unset"
}

export const getDeployEnv = (): string => {
  return process.env.NEXT_PUBLIC_DEPLOY_ENV || "unset"
}

export const getAppMetaData = () => ({
  nodeEnv: getNodeEnv(),
  deployEnv: getDeployEnv(),
  appVersion: getAppVersion(),
})

export const isDevEnv = getDeployEnv() === DeployEnv.DEV

export const isStageEnv = getDeployEnv() === DeployEnv.STAGE

export const isProdEnv = getDeployEnv() === DeployEnv.PROD

export const isProduction = isProdEnv && !isDevEnv && !isStageEnv
