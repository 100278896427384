const pullRequestId = process.env.NEXT_PUBLIC_VERCEL_GIT_PULL_REQUEST_ID as string
const commitMessage = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE as string
const commitSha = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA as string
const branchName = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF as string
const repoBaseUrl = process.env.NEXT_PUBLIC_GIT_REPO_URL as string

export type GitCommitInfoFlag = {
  githubRepoUrl: string
}

export type GithubDeployInfo = {
  commitUrl: string
  commitLinkCaption: string
  pullRequestUrl: string
  pullRequestLinkCaption: string
}

export const getGithubDeployInfo = (): GithubDeployInfo => {
  const commitUrl = `${repoBaseUrl}/commit/${commitSha}`
  const commitLinkCaption = commitMessage || branchName || commitSha

  let pullRequestUrl = ""
  let pullRequestLinkCaption = ""
  if (pullRequestId) {
    pullRequestUrl = `${repoBaseUrl}/pull/${pullRequestId}`
    pullRequestLinkCaption = branchName || pullRequestId || commitSha
  }

  return {
    pullRequestUrl,
    pullRequestLinkCaption,
    commitUrl,
    commitLinkCaption,
  }
}
