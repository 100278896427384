import { zodResolver } from "@hookform/resolvers/zod"
import React, { PropsWithoutRef, ReactNode, useState } from "react"
import { FormProvider, UseFormProps, useForm } from "react-hook-form"
import { z } from "zod"

import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { Alert, Button, Fab, Grid } from "@mui/material"
import { Box } from "@mui/system"

export enum SubmitType {
  DEFAULT,
  NEXT,
  HIDDEN,
}

export interface FormProps<S extends z.ZodType<any, any>>
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements["form"]>, "onSubmit"> {
  /** All your form fields */
  children?: ReactNode
  /** Text to display in the submit button */
  submitText?: string
  submitType?: SubmitType
  schema?: S
  onSubmit: (values: z.infer<S>) => Promise<void | OnSubmitResult>
  initialValues?: UseFormProps<z.infer<S>>["defaultValues"]
}

interface OnSubmitResult {
  FORM_ERROR?: string
  [prop: string]: any
}

export const FORM_ERROR = "FORM_ERROR"

export function Form<S extends z.ZodType<any, any>>({
  children,
  submitText,
  submitType = SubmitType.DEFAULT,
  schema,
  initialValues,
  onSubmit,
  ...props
}: FormProps<S>) {
  const ctx = useForm<z.infer<S>>({
    mode: "onChange",
    resolver: schema ? zodResolver(schema) : undefined,
    defaultValues: initialValues,
  })
  const [formError, setFormError] = useState<string | z.ZodError | null>(null)

  return (
    <FormProvider {...ctx}>
      <form
        onSubmit={ctx.handleSubmit(async (values) => {
          const result = (await onSubmit(values)) || {}
          for (const [key, value] of Object.entries(result)) {
            if (key === FORM_ERROR) {
              setFormError(value)
            } else {
              ctx.setError(key as any, {
                type: "submit",
                message: value,
              })
            }
          }
        })}
        data-control-id="form"
        {...props}
      >
        {/* Form fields supplied as children are rendered here */}
        {children}

        <Box component="div" minHeight={70}>
          {formError &&
            (formError instanceof z.ZodError ? (
              formError.issues.map((e) => (
                <Alert key={e.code} severity="error">
                  {e.message}
                </Alert>
              ))
            ) : (
              <Alert severity="error">{formError}</Alert>
            ))}
        </Box>

        {submitType === SubmitType.NEXT && (
          <Grid container marginRight={4} justifyContent="end">
            <Fab
              variant="extended"
              color="primary"
              aria-label="next"
              type="submit"
              disabled={ctx.formState.isSubmitting || !ctx.formState.isValid}
            >
              <ArrowForwardIcon sx={{ mr: 1 }} />
              {submitText || "Next"}
            </Fab>
          </Grid>
        )}

        {submitType === SubmitType.DEFAULT && (
          <Button
            data-control-id="start-submit"
            type="submit"
            disabled={ctx.formState.isSubmitting || !ctx.formState.isValid}
            fullWidth
            variant="contained"
            sx={{ mb: 2 }}
          >
            {submitText || "Submit"}
          </Button>
        )}
      </form>
    </FormProvider>
  )
}

export default Form
