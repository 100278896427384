import { forwardRef } from "react"
import { useFormContext } from "react-hook-form"

import { TextField, TextFieldProps } from "@mui/material"
import FormHelperText from "@mui/material/FormHelperText"
import { Box } from "@mui/system"

export const LabeledTextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ name = "formFieldName", children, inputProps, ...props }, ref) => {
    const {
      register,
      formState: { isSubmitting, errors },
    } = useFormContext()
    const error = errors[name]?.message as unknown as string
    props.color = error ? "error" : "success"

    return (
      <Box data-control-id="labeled-text-field" sx={{ mb: 2 }}>
        <TextField
          data-control-id={`${name}-input`}
          name={name}
          inputProps={{ ...register(name), disabled: isSubmitting, ...inputProps }}
          {...props}
        />
        <Box sx={{ minHeight: "1.3em" }}>
          <FormHelperText error={true}>{error}</FormHelperText>
        </Box>
        {children}
      </Box>
    )
  }
)

export default LabeledTextField
