import React, { ReactEventHandler } from "react"

import CloseIcon from "@mui/icons-material/Close"
import { Alert, AlertColor, IconButton } from "@mui/material"

type NotifyAlertProps = {
  color?: AlertColor
  message: string
  onClose?: ReactEventHandler
}

export const NotifyAlert: React.FC<NotifyAlertProps> = ({
  color = "success",
  message,
  onClose,
}) => {
  return (
    <Alert
      severity={color}
      action={
        onClose && (
          <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )
      }
    >
      {message}
    </Alert>
  )
}
