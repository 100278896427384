import { Routes } from "@blitzjs/next"
import { useRouter } from "next/router"

import { Box, Breadcrumbs } from "@mui/material"

import { getParentNavItemByPathname } from "src/core/helpers/navigationHelpers"

import Link from "src/core/components/Link"

export const FooterBreadcrumbs = () => {
  const router = useRouter()
  const parentNavItem = getParentNavItemByPathname(router.pathname)

  return (
    <Box sx={{ p: "5px 10px", ml: "35px" }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link href={Routes.Home()}>Home</Link>
        <Link href={parentNavItem.route}>{parentNavItem.name}</Link>
      </Breadcrumbs>
    </Box>
  )
}
