import { hotjar } from "react-hotjar"

/** Init tracking UX monitoring to hotjar.com **/
export const initHotjar = () => {
  const HOTJAR_ID = parseInt(process.env.NEXT_PUBLIC_HOTJAR_ID as string, 10)
  const HOTJAR_SV = parseInt(process.env.NEXT_PUBLIC_HOTJAR_SV as string, 10)

  if (HOTJAR_ID || HOTJAR_SV) {
    hotjar.initialize(HOTJAR_ID, HOTJAR_SV)
  }
}
