import { useFlags } from "flagsmith/react"
import { useState } from "react"

import { Button, Chip, Tooltip, styled } from "@mui/material"

import { getDevModeInfo } from "../helpers/versionUiHelpers"

import { DeployInfoPane } from "./DeployInfoPane"

type DeployEnvChipProps = {
  size?: "small" | "medium" | "large"
}

export const DeployEnvButton: React.FC<DeployEnvChipProps> = ({ size }) => {
  const [opened, setOpened] = useState(false)

  const devMode = getDevModeInfo()

  const flags = useFlags(["title_version_tag", "git_commit_info"])
  const hasTitleVersionTag = flags.title_version_tag.enabled

  const title = flags.git_commit_info.enabled ? <DeployInfoPane /> : flags.title_version_tag.value

  const handleToogle = () => {
    setOpened(!opened)
  }

  return hasTitleVersionTag ? (
    <Tooltip
      data-control-id="deploy-env-button"
      title={title}
      placement="bottom"
      arrow
      open={opened}
    >
      <Button
        color={devMode.color}
        variant="contained"
        size={size}
        onClick={handleToogle}
        sx={{ p: 0, m: 0, fontSize: 10 }}
      >
        {devMode.label}
      </Button>
    </Tooltip>
  ) : null
}
