import { debounce } from "lodash"

export const DEBOUNCE_CHANGE_MS = 5000
export const DEBOUNCE_SAVE_MS = 1000
export const DEBOUNCE_SEARCH_MS = 1000
export const DEBOUNCE_AUTOCOMPLETE_SEARCH_MS = 500
export const HIDE_NOTIFY_MS = 3000
export const COLLAPSE_MS = 1000
export const FAB_MENU_HIDE_MS = 2000
export const debounceChange = (action) => debounce(action, DEBOUNCE_CHANGE_MS)
export const debounceSearch = (action) => debounce(action, DEBOUNCE_SEARCH_MS)
export const debounceAutocompleteSearch = (action) =>
  debounce(action, DEBOUNCE_AUTOCOMPLETE_SEARCH_MS)
export const debounceSave = (action) => debounce(action, DEBOUNCE_SAVE_MS)
export const getHideNotifyDelay = () => HIDE_NOTIFY_MS
export const getCollapseTimeout = () => COLLAPSE_MS
export const getFabMenuHideTimeout = () => FAB_MENU_HIDE_MS
