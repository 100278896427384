export const pageview = (url) => {
  if (!window || !window.gtag) return

  window.gtag("config", process.env.NEXT_PUBLIC_GTAG_ID, {
    page_path: url,
  })
}
export const event = ({ action, category, label, value }) => {
  if (!window || !window.gtag) return

  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}
